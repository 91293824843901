



























// @vuese
// @group Views
// ForgotPassword

import { Login as LoginRoute } from "../router";
import { validateEmailAddress } from "../components/utils/EmailParser";
import Component from "vue-class-component";
import { Store } from "@/store/store";

@Component({
  metaInfo: {
    title: "Forgotten Password"
  }
})
export default class ForgotPassword extends Store {
  componentTitle = "Forgot Your Password";
  email = "";
  transmittingMessage = false;
  get isValidEmail() {
    return validateEmailAddress(this.email).length;
  }

  /**
   * Submit an email address to request the password reset
   * @arg {String} email - email for reset link to be sent to
   * @vuese
   */
  async submit(email: string) {
    this.transmittingMessage = true;
    if (validateEmailAddress(email).length != 1) {
      this.$toast.error("Email Address is not valid");
      // this.transmittingMessage = false
      return;
    }

    await this.forgotPassword(email).catch(() => {});

    this.$toast.success("Email Address has been sent");
    this.$router.push(LoginRoute);
  }
}
